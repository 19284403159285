import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import moment from 'moment';
import authorPlaceHolder from "./author-placeholder.webp";

import { grpahCMSImageLoader } from '@monorepo-nx/std-watch/services';

export const Author = ({ author }) => (
  <div className="text-center mt-20 mb-8 p-12 relative rounded-lg bg-black bg-opacity-20">
    <div className="absolute left-0 right-0 -top-14">
      <Image
        loader={grpahCMSImageLoader}
        alt={author.name ? author.name : "author"}
        height="100"
        width="100"
        className="align-middle rounded-full"
        src={author.picture ? author.picture.url : '/some/author/url'}
      />
    </div>
    <h3 className="text-white mt-4 mb-4 text-xl font-bold">{author.name}</h3>
    <p className="text-white text-ls">{author.biography}</p>
  </div>
);

export const AuthorBanner = ({ author, createdAt }) => {
  return (
  <div className="items-center  md:justify-start px-4 mt-10 lg:mt-4  ml-4 flex lg:w-auto ">
    {/* <Link href={author ? "/medical-team/" + author.slug : ''} >
      </Link> */}
        <Image
            loader={grpahCMSImageLoader}
            alt={author ? author.name : 'author'}
            priority
            height="60"
            width="60"
            className=" rounded-full "
            src={author && author.picture ? author.picture.url : authorPlaceHolder} />
    <div className="ml-4 md:ml-8">
        <div className=" items-center py-2 w-full ">
            <div className=" md:ml-8">
                <div className=" flex space-x-2   ">
                        <p className=" text-gray-500 hover:text-gray-900 mb-2 " >{author ? author.name : ''}</p>
                    {/* <Link href={author ? "/medical-team/" + author.slug : ''} legacyBehavior>
                    </Link> */}
                    {/* <Link href={"#"} legacyBehavior>
                        <a className=" text-gray-500 hover:text-gray-500 ">
                            <ContactSection />
                        </a>
                    </Link> */}
                </div>
                <div className="flex flex-row sm:pt-2 gap-4 md:gap-5 lg:gap-10 items-center ">
                    <span className=" text-xs text-gray-500"> {moment(createdAt).format('MMM DD, YYYY')}</span>
                    {/* <span className="text-gray-500 text-xs ">
                        7 min read
                    </span> */}
                </div>
            </div>
        </div>
    </div>
  </div>)
}

