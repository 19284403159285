import { AppContext } from '@monorepo-nx/std-watch/services';
import Link from 'next/link';
import { useContext } from 'react'
// import { Disclosure } from '../disclosure/disclosure';

interface FooterProps {
  complianceLinks: Array<any>
  disclaimer: any
  domain: String
}
export function Footer({ complianceLinks = [], disclaimer, domain }: FooterProps) {

  const { footerData } = useContext(AppContext);
  const footerLinks = [...footerData || []]

  return (
    <footer className="bg-[#354276]  p-6 sm:p-8" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-8xl  sm:px-6 sm:py-4 md:px-8">
        <div className="mt-12 mb-2 grid md:grid-cols-3 grid-cols-2 gap-8  xl:mt-0">

          {footerLinks.map((item, id) => (
            <div key={id}>
              <h3 className="text-base font-medium text-indigo-300">{item.NavMenuDropdown_title_alias}</h3>
              <ul className="mt-4 space-y-3 ">
                {item.NavMenuDropdown_links_alias.map((i, id) => (
                  <li key={id} >
                    <a href={i.url} className="text-sm text-gray-300 hover:text-gray-500">
                      {i.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {/* Mobile */}
          <div className="md:hidden grid">
            <h3 className="text-base  md:mt-0 font-medium text-indigo-300">Quick Links</h3>
            {complianceLinks.map((item: any, id) => (
              <a key={id} href={item?.url} className="text-sm md:text-center text-gray-300 hover:text-gray-500">
                {item.title}
              </a>
            ))}
          </div>
          {disclaimer ?
            <div >
              <h3 className="text-base  md:mt-0 font-medium text-indigo-300"> Disclaimer</h3>
              <div className="my-4  ">
                <a href={disclaimer.url} className="lg:text-base text-sm text-gray-300 hover:text-gray-500">
                  {disclaimer.title}
                </a>
              </div>
            </div> : ""}

        </div>
      </div>

      {/* Desktop */}
      <div className=" border-t border-[#7278c7] ">
        <div className="hidden mx-auto max-w-8xl mt-4 md:mt-1 md:grid md:grid-cols-5">
          {complianceLinks.map((item, id) => (
            // use regular a tags to avoid having urls nextified, it's needed until compliance pages get migrated
            <a key={id} href={item.url} className="text-sm md:text-center text-gray-300 hover:text-gray-500">
              {item.title}
            </a>
          ))}
        </div>
        <p className=" my-8 text-base text-gray-300 md:text-center">&copy; {(new Date().getFullYear())} {domain} - All rights reserved.</p>
      </div>
    </footer>
  );
}
export default Footer;
