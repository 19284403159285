import Link from 'next/link';
import Image from 'next/image';
import clsx from 'clsx';
import { forwardRef, Fragment, useContext, useState  } from 'react';
import { useRouter } from 'next/router';
import { AccordionCollapse, SearchPalette } from '@monorepo-nx/std-watch/ui';
import { AppContext } from '@monorepo-nx/std-watch/services';
import { Popover, Transition } from '@headlessui/react';
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SendEvent } from '@monorepo-nx/std-watch/services';

import logoDesk from "./logo-std.png";
import logomob from "./logomob.png";


const MyLink = forwardRef((props, ref) => {
  let { href, children, ...rest } = props
  return (
    <a href={href} ref={ref} {...rest}>
      {children}
    </a>
  )
})

export const NavigationLink = forwardRef(({ href, text, isActive, children, ...rest }, ref) => {
  const {siteConfigData} = useContext(AppContext);
  const primaryColor = siteConfigData?.colorPalette?.primaryColor ?? 'gray';
  return (
      <a href={href} ref={ref} {...rest} className={clsx(
        'flex w-full flex-col text-center items-center text-base justify-center border-b-2',
        isActive
          ? `border-${primaryColor}-600  text-${primaryColor}-700 besfore:text-${primaryColor}-700`
          : `border-transparent before:text-slate-500 hover:border-${primaryColor}-600 hover:before:text-slate-900`
      )}>
        {children}
      </a>
          
  )
})

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function NavBar({showSearchBar, menuData, logo, logoMobile}) {
  const router = useRouter();
  const {siteConfigData} = useContext(AppContext);
  const primaryColor = siteConfigData?.colorPalette?.primaryColor ?? 'gray';

  return (
    <Popover className="relative max-w-6xl  mx-auto " onClick={e =>   SendEvent({eventName: 'click-component', component: 'navbar', text: e.target.innerText, href: e.target.href})}>
      {({ open }) => (
        < >
          <div className="absolute inset-0 z-30 pointer-events-none" aria-hidden="true" />
          <div className="relative  z-50">
            <div className="max-w-8xl mx-auto  flex justify-between items-center px-3 lg:px-0 ">

              {/* Mobile */}
              <div className="flex xl:hidden lg:pt-3 lg:mr-3 items-center">
                <a href='/'>
                  <Image
                    src={logoMobile? logoMobile.url : logomob.src}
                    alt="logo"
                    height="108"
                    width="108"
                    quality={100}
                  />
                </a>

              </div>
              <div className="flex lg:hidden  w-3/4 items-center">
                {showSearchBar ? <SearchPalette /> : ''}
              </div>
              <div className="lg:hidden ">
                <div className="flex space-x-4">
                  <Popover.Button className="inline-flex items-center justify-center focus:outline-none m-3">
                    <span className="sr-only">Open menu</span>
                    <Image
                      src="https://cdn.stdwatch.com/assets/design/menu/toggle-menu.svg"
                      width="50"
                      height="50"
                      alt="menu"
                      aria-hidden="true" style={{fill: primaryColor}} />
                  </Popover.Button>
                </div>
              </div>
              <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-300 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute  z-50 top-0 inset-x-1 transition lg:hidden">
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-16 px-5 ">
                      <div className="flex items-center justify-end pr-4">
                        <div className="-mr-2">
                          <Popover.Button className={`bg-white rounded-md inline-flex items-center justify-center text-gray-400  hover:text-gray-500 hover:bg-gray-100 hover:ring-2 hover:ring-${primaryColor}-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${primaryColor}-500`}>
                            <span className="sr-only">Close menu</span>
                            < XMarkIcon className="h-8 w-8" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="grid grid-cols-1 gap-1">
                          <ul role="list" className=" space-y-2 ">
                            {menuData.map((button, index) => (
                              (!button.NavMenuDropdown_title_alias) ?
                                <li key={index} className="flex">
                                  <Popover.Button
                                    as="a"
                                    href={button.NavMenuLink_url_alias}
                                    className={`text-gray-700 rounded-md border w-full border-gray-300 shadow-sm text-center py-2 bg-white hover:ring-2 hover:ring-${primaryColor}-500 `}>
                                    <span className="py-4" >{button.NavMenuLink_title_alias}</span>
                                  </Popover.Button>
                                </li> :
                                <li key={index} >
                                  <div className="grid grid-cols-1 gap-1">
                                    <AccordionCollapse button={button} /> </div>
                                </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>

              {/* Desktop */}
              <div className="hidden xl:flex  flex-shrink-0 mr-6 mt-2 items-center">
                <a href='/' >
                  <Image
                    src={logo? logo.url : logoDesk.src}
                    alt="logo"
                    height="27"
                    width="112"
                    quality={100}
                  />
                </a>
              </div>
              <div className="hidden lg:flex-1 mt-2 lg:flex items-center lg:justify-end">
                <div className="lg:flex-1 items-center ">
                {showSearchBar ? <SearchPalette /> : ''}
                </div>
                <Popover.Group as="nav" className="flex ml-4 space-x-6 ">
                  {menuData.map((button, index) => (
                    (!button.NavMenuDropdown_title_alias) ?
                      <Popover key={index} >
                        <NavigationLink 
                          href={button.NavMenuLink_url_alias} 
                          isActive={router.asPath.includes(`/${button.NavMenuLink_url_alias.split('/').pop()}`)}
                         >
                            {button.NavMenuLink_title_alias}
                        </NavigationLink>
                      </Popover > :

                      <Popover key={index}>
                        {({ open }) => (
                          < >
                          
                            <Popover.Button
                              className={clsx(classNames(
                                open ? 'text-gray-700' : 'text-black',
                                `group -mr-4 inline-flex items-center text-base focus:outline-none 
                                focus:border-b-2 focus:border-b-${primaryColor}-500
                                `
                              ))}
                            >
                              <span className="">{button.NavMenuDropdown_title_alias}</span>

                              <ChevronDownIcon
                                className={clsx(classNames(
                                  open ? 'text-gray-700' : 'text-black',
                                  'ml-0.5 h-4 w-4 group-hover:text-gray-700'
                                ))}
                                aria-hidden="true"
                              />
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute z-10 transform -translate-x-1/3 mt-4 max-w-md">
                                <div className="shadow-lg ring-black ring-opacity-5 overflow-hidden">
                                  <div className="relative bg-white">
                                    <ul role="list" >
                                      {button.NavMenuDropdown_links_alias?.map((dropdown, index) => (
                                        <li key={index}  className={`pl-2 pr-6 group hover:bg-${primaryColor}-100`}>
                                            <Popover.Button as={MyLink} href={dropdown.url} 
                                              className={`p-1 font-medium text-${dropdown.isColored ? primaryColor : "gray"}-600 group-hover:text-${dropdown.isColored ? primaryColor : "gray"}-500 text-base truncate flex items-center`}>
                                              {' '}
                                              {dropdown.title}
                                               <ArrowRightIcon className="absolute group-hover:animate-bounce-right right-2 h-5 w-5"/>
                                            </Popover.Button>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </>
      )}
    </Popover>
  );
}


export default NavBar;
