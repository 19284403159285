import Script from "next/script";
import Head from 'next/head'
import { Header, ScrollToTop } from '@monorepo-nx/std-watch/ui';
import { Footer } from '@monorepo-nx/lander/ui';

interface LayoutProps {
  children: React.ReactNode;
  domain: String
  complianceLinks: any
  disclaimer: any
}
const Layout = ({ children, domain, complianceLinks, disclaimer }: LayoutProps) => (

  <>
    {/* <Head>
    </Head> */}
    <ScrollToTop />
    {children}
    <Footer domain={domain} complianceLinks={complianceLinks} disclaimer={disclaimer} />
  </>
);

export default Layout;
