import Image from 'next/image';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { AffiliateLinkContainer } from '@monorepo-nx/std-watch/ui';
import { useContext } from 'react';
import { AppContext } from '@monorepo-nx/std-watch/services';
import clsx from 'clsx';

export const FlashingButton = ({ primaryColor }) => (
  // TODO: Move this into a separate library
  <span className={clsx(`relative flex h-9 w-9 items-center justify-center rounded-full bg-${primaryColor}-100 hover:bg-${primaryColor}-200 `)}>
    <span className="sr-only">Open options</span>
    <ChevronRightIcon className="h-3 w-3 text-cyan-900" aria-hidden="true" />
    <div className={clsx(`animate-[ping_4s_cubic-bezier(0,0,0.2,1)_infinite] absolute inline-flex h-7 w-7 rounded-full bg-${primaryColor}-300 opacity-90`)}>
      <button className="relative inline-flex h-9 w-9 items-center justify-center"></button>
    </div>
  </span>
)
// create interface
interface MiniChartComponent {
  chart: any;
  limit: number;
}
export function MiniChart({ chart, limit = 3 }: MiniChartComponent) {

  const { siteConfigData } = useContext(AppContext);
  const primaryColor = siteConfigData?.colorPalette?.primaryColor || 'indigo';
  if (!chart) {
    chart = siteConfigData?.miniChart;
  }

  // TODO: remove this hack, it is a result of the blog index not fetching the siteconfig data, and therefore the minichart isn't available
  if (!chart) {
    return null;
  }
  const partners = chart.promotions
  return (
    <div>
      <div className={clsx(`bg-${primaryColor}-100 p-2 rounded-lg text-center font-light text-cyan-900`)}>Top Services</div>
      <ul className="grid grid-cols-1 divide-y divide-gray-200  border-b border-gray-200 mb-10 ">
        {partners.slice(0, limit).map((partner, index) => (
          <AffiliateLinkContainer                 //  legacyBehavior
            key={index}
            affName={partner.deepLink?.name}
            section="sidebar-right"
            component="top-3"
          >
            <div key={partner.id} className="col-span-1 flex item-center justify-between p-2">
              <p >{index + 1}</p>
              <li key={index} className=" w-3/5 relative ">
                <Image
                  src={partner.advertiser?.logo.url}
                  alt={partner.advertiser?.name}
                  width={500}
                  height={250}
                />
              </li>
              <div className="flex items-center" key={index + 1}>
                <FlashingButton primaryColor={primaryColor} />
              </div>
            </div>
          </AffiliateLinkContainer>
        ))}
      </ul>
    </div>
  );
}
export default MiniChart;
